.me-social{
  margin-top: 2rem;
  hr{
    width: 30%;
  }
  .title{
    font-size: 1.5rem;
    font-weight: 600;
    color: #555;
  }
  .item{
    padding: 1rem;
    &:hover{
      img{
        transform: scale(1.1);
        // filter: grayscale(0%);
        // opacity: 1;
      }
      .so-name{
        color: #4682b4;
      }
    }
  }
  .so-img{
    height: 100px;
    text-align: center;
    padding: 10px;
    img{
      height: 100%;
      transition: transform .2s;
      // filter: grayscale(100%);
    }
  }
  .so-name{
    font-size: 1.1rem;
    font-weight: 600;
    color: #555;
    text-align: center;
    margin-top: .5rem;
  }
}